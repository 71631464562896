import { PlatformID } from '@common/clients/api';
import {
    DisplayType,
    getDisplayTypeByPlatform,
    getDisplayTypeSwitchOptionsByPlatform,
    Props,
} from '@web/molecules/NewsList';

export const isToday = (dateString: string): boolean => {
    const today = new Date();
    const date = new Date(dateString);
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

export interface DisplayConfig {
    displayType: DisplayType;
    displayTypeSwitchOptions?: DisplayType[];
    mobileDisplayType?: DisplayType;
    showIconTabs: boolean;
    skipDivider?: boolean;
}

export const getDisplayConfig = (
    platform: PlatformID,
    props?: Pick<Props, 'displayType' | 'mobileDisplayType'>,
): DisplayConfig => {
    const displayType = props?.displayType || getDisplayTypeByPlatform(platform);

    switch (platform) {
        case PlatformID.VI:
            return {
                showIconTabs: false,
                skipDivider: true,
                displayType: DisplayType.blocks,
                mobileDisplayType: DisplayType.list,
            };
        default:
            return {
                showIconTabs: true,
                displayType,
                displayTypeSwitchOptions: getDisplayTypeSwitchOptionsByPlatform(platform),
                mobileDisplayType: props?.mobileDisplayType,
            };
    }
};
